<template>
  <v-layout class="fill-height kiwi-bg-light-grey-9 kiwi-site-main-layout kiwi-site-dashboard"
  :class="!rail ? 'kiwi-open-drawer' :  ''"
  >
    <ChangeTypeModal v-if="(isFirstLogin) && isRenderReady" @dismiss-intro="completeIntro"/>

    <v-navigation-drawer
      v-model="drawer"
      :rail="rail"
      mobile-breakpoint="sm"
      @click="rail = false"
      :class="closeSidebarMobile ? 'close-sidebar-for-mobile' : ''"
      width="220"
      style="position: fixed; left: 0; max-height: 100vh;"
    >
      <v-list-item
        nav
        class="kiwi-bg-primary"
        style="height: 64px; border-radius: 0;"
      >
        <img :src="institute.invertedLogo" max-height="40" max-width="165" class="mt-1 logo" v-if="!rail" alt="kiwi logo">
        <img :src="institute.invertedFavicon" width="40" v-else class="logo" :class="'cursor-pointer'" alt="kiwi logo">
        <template v-slot:append>
          <img @click.stop="rail = !rail" v-if="rail === false" :class="!rail ? 'cursor-pointer' :  ''" src="/assets/admin_menu.svg" alt="">
        </template>
      </v-list-item>

      <v-divider></v-divider>

      <Navigation @close:sidebarForMobile="closeSidebarOnMobile" />

      <template v-slot:append>
        <v-divider></v-divider>
        <div :class="!rail ? 'px-2 py-3' : 'py-2 px-1'">
          <v-btn block class="kiwi-button text-capitalize kiwi-radius-4 font-weight-semi-bold kiwi-text-white kiwi-bg-upgrade text-center mr-3" height="44" size="large" variant="flat" @click="router.push({name: 'subscription'})"><v-icon class="la la-crown" :class="!rail ? 'mr-2' : ''"></v-icon> <span v-if="!rail">Upgrade Plan</span></v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar class="kiwi-bg-primary kiwi-nav-app-bar kiwi-text-white" elevation="0" density="comfortable" height="72"
      style="position: fixed;"
    >
      <v-toolbar-title class="header-title">
        {{appBarTitle??''}}
      </v-toolbar-title>
      <v-list-item
        nav
        class="kiwi-bg-primary header-logo"
        style="height: 64px; border-radius: 0;"
        @click.stop="rail = !rail"
      >
        <img :src="institute.invertedLogo" max-height="40" max-width="165" v-if="!rail" alt="kiwi logo">
        <img :src="institute.invertedFavicon" width="40" v-else :class="'cursor-pointer mt-2'" alt="kiwi logo">
      </v-list-item>
      <v-spacer></v-spacer>
      <v-btn v-if="free_package" class="kiwi-button text-capitalize kiwi-radius-4 font-weight-semi-bold kiwi-text-white kiwi-bg-upgrade text-center mr-3" height="44" size="large" variant="flat" @click="router.push({name: 'subscription'})"><v-icon class="la la-crown mr-md-2"></v-icon> <span class="d-none d-md-block">Upgrade Plan</span></v-btn>
      <v-btn v-else class="kiwi-button text-capitalize main-layout-upgrade-btn kiwi-radius-4 font-weight-semi-bold kiwi-text-white kiwi-bg-upgrade text-center mr-3" height="44" size="large" variant="flat" @click="router.push({name: 'subscription'})"><img :src="user?.current_package?.image" style="width: 24px; height:24px; object-fit: contain;" class="mr-2"> <span class="gradient-text-1 font-weight-bold upgrade-gradient-text" v-if="!rail">{{ user?.current_package?.name }}</span></v-btn>
      <!-- <v-menu
        v-model="menu"
        :close-on-content-click="false"
        location="bottom"
      >
        <template v-slot:activator="{ props }">
          <v-btn 
            class="kiwi-button text-none kiwi-radius-4 kiwi-bg-white kiwi-text-primary font-weight-bold mr-3" 
            height="44" size="large" 
            v-bind="props"
            variant="flat"
          ><v-icon class="mdi mdi-plus-circle-outline mr-2" /> Change Exam</v-btn>
        </template>
        <v-card>

        </v-card>
      </v-menu> -->
      <v-menu
        v-model="changeExamTypeMenu"
        location="bottom"
        offset="15 100"
        max-width="400"
        variant="flat"
        elevation="0"
        content-class="change-exam-type notification-module rounded-lg w-100"
      >
        <template v-slot:activator="{ props }">
          <div  v-bind="props" class="position-relative">
            <v-btn
              class="kiwi-button change-examtype-btn text-capitalize kiwi-radius-4 mr-3 position-relative align-center px-1 pr-3 font-weight-bold position-relative"
              height="44"
              color="#116544"
              id="exam-type-btn"
              style="background: #D4F1CD; z-index: 100;"
            >
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
              <g filter="url(#filter0_d_13270_2005)">
              <g filter="url(#filter1_i_13270_2005)">
              <path d="M27.3332 18.9998C27.3332 14.3975 23.6022 10.6665 18.9998 10.6665C14.3975 10.6665 10.6665 14.3975 10.6665 18.9998C10.6665 23.6022 14.3975 27.3332 18.9998 27.3332C23.6022 27.3332 27.3332 23.6022 27.3332 18.9998Z" fill="#16885B"/>
              </g>
              <path d="M27.3332 18.9998C27.3332 14.3975 23.6022 10.6665 18.9998 10.6665C14.3975 10.6665 10.6665 14.3975 10.6665 18.9998C10.6665 23.6022 14.3975 27.3332 18.9998 27.3332C23.6022 27.3332 27.3332 23.6022 27.3332 18.9998Z" stroke="#ADE6AD" stroke-width="1.5"/>
              <path d="M19.0003 15.6665V22.3332M22.3337 18.9998H15.667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
              <filter id="filter0_d_13270_2005" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_13270_2005"/>
              <feOffset/>
              <feGaussianBlur stdDeviation="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.109804 0 0 0 0 0.662745 0 0 0 0 0.447059 0 0 0 0.6 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13270_2005"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13270_2005" result="shape"/>
              </filter>
              <filter id="filter1_i_13270_2005" x="9.9165" y="9.9165" width="18.1665" height="22.1665" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="4"/>
              <feGaussianBlur stdDeviation="2.5"/>
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.125 0 0 0 0 0.6 0 0 0 0 0.413393 0 0 0 1 0"/>
              <feBlend mode="normal" in2="shape" result="effect1_innerShadow_13270_2005"/>
              </filter>
              </defs>
            </svg>
            <span class="d-none d-md-inline-block">Change Exam {{ streams.length ? '('+streams.find(st => st.id === user.stream.id).name+')' : '' }}</span>
            <img src="/assets/dot-change-type.png" alt="" class="position-absolute" style="top: -5px; right: -10px; z-index: 2;">
            </v-btn>
          </div>
        </template>
        <v-list>
          <v-list-item>
            <v-list-title><strong>Select Exam</strong></v-list-title>
          </v-list-item>
          <v-divider />
          <v-list-item v-for="stream in streams" :key="stream.id" @click="chooseExamType(stream.id)" class="cursor-pointer" :class="{ 'v-list-item--active' : stream.id == user.stream.id }">
            <v-list-title><strong>{{ stream.name }}</strong> {{ stream.sub_text ? `(${stream.sub_text})` : '' }}</v-list-title>
            <v-chip class="ml-2 kiwi-bg-upgrade kiwi-text-whtie kiwi-fs-12" size="small" variant="flat" v-if="stream.id == user.stream.id" rounded="lg">Active</v-chip>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
            class="kiwi-button px-4 kiwi-bg-primary-dark kiwi-text-white align-items-center text-capitalize kiwi-radius-4 kiwi-button-icon mr-3 position-relative"
            size="large"
            height="44"
            variant="flat"
            v-bind="props"
            v-if="switchBackEnable"
            @click="switchBack"
          >
            <div class="d-flex align-center justify-content-center px-2">
              <img :src="'assets/logout-01_active.svg'" style="width: 20px;" />
              <span class="pl-1 hide-on-mobile" >Switch Back</span>
            </div>
          </v-btn>
      <v-menu
        v-model="notificationToggle"
        :close-on-content-click="false"
        location="bottom"
        offset="15 100"
        max-width="460"
        variant="flat"
        elevation="0"
        content-class="notification-module rounded-lg w-100"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            class="kiwi-button text-capitalize kiwi-radius-4 kiwi-button-icon mr-3 position-relative"
            size="large"
            height="44"
            min-width="44"
            variant="flat"
            :class="notificationToggle ? 'kiwi-bg-white' : 'kiwi-bg-primary-dark'"
            v-bind="props"
          >
            <img :src="notificationToggle ? '/assets/notification-01.svg' : '/assets/BellRinging.svg'" />
            <v-badge v-if="unseenCounter" :color="institute.primaryColor" :content="unseenCounter" class="position-absolute" size="24" style="top: 2px; right: 4px;"></v-badge>
          </v-btn>
        </template>

        <Notification />
      </v-menu>
      <v-menu
        v-model="profileMenuToggle"
        :close-on-content-click="true"
        location="bottom"
        offset="15 100"
        max-width="300"
        variant="flat"
        elevation="0"
        content-class="notification-module profile-module rounded-lg w-100"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            class="kiwi-button kiwi-profile-btn text-capitalize kiwi-radius-4 mr-3 px-3"
            :class="profileMenuToggle?'kiwi-bg-white kiwi-text-primary':'kiwi-bg-primary-dark kiwi-text-white'"
            size="large" height="44" variant="flat" v-bind="props"
          >
            <v-avatar class="kiwi-profile-avatar font-weight-semi-bold kiwi-border-white-dim-20 mr-2" image="/assets/PLaceholder.svg" size="34"></v-avatar>
            <span class="hide-on-mobile">{{user?limitString(user.user_full_name, 25):'User'}}</span>
          </v-btn>
        </template>
        <v-card min-width="300">
          <div class="d-flex flex-wrap flex-column px-4 py-3 kiwi-bg-light-grey-5" style="grid-gap: 0px">
            <div class="font-weight-bold kiwi-fs-18 kiwi-text-black-3 text-capitalize">
              {{user?user.user_full_name:'User'}}
            </div>
            <span>{{user?user.email:''}}</span>
          </div>
          <v-divider />
          <v-list lines="one">
            <template  v-for="(item, index) in items">
            <v-list-item
              :to="item.value ? {name: item.value} : ''"
              :prepend-avatar="item.icon"
              class="cursor-pointer"
              v-if="item.value === 'referal' ? institute.$hasFeature('referral') : true"
            >
              <v-list-item-title v-if="item.label === 'Logout'" @click="confirmDialog = true">{{ item.label }}</v-list-item-title>
              <v-list-item-title v-else>
                {{ item.label }}
                <template v-if="item.value === 'subscription'">
                  <v-chip class="ml-2 kiwi-bg-upgrade kiwi-text-whtie kiwi-fs-12" size="small" variant="flat">{{ free_package ? 'You are on free plan' : `Plan left for ${user?.remaining_subscription_days} days` }}</v-chip>
                </template>
              </v-list-item-title>
            </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-main class="d-flex kiwi-page-main-wrapper" :class="!rail ? 'kiwi-drawer-expand' : 'kiwi-drawer-shrink'" style="min-height: 300px;"
    :style="rail ? {'--v-layout-left': '56px'} : {'--v-layout-left': '220px'}">
      <div class="pa-5 w-100">
        <div class="kiwi-fs-18 font-weight-semi-bold kiwi-text-black mb-4" v-if="isMobileView">{{pages[currentPage]}}</div>
        <!-- <slot /> -->
        <router-view />
      </div>
    </v-main>
    <SnackBar />
    
  </v-layout>
  <ModalDialog class="text-center" @update:dialog="confirmDialog = false" @confirm="logoutUser" v-if="confirmDialog" >
      <template #title>
          <div class="w-100 mb-2 mt-7">
            <img src="/assets/Notifications.svg" alt="">
          </div>
          <span class="kiwi-fs-24 font-weight-bold">Confirm Logout</span>
      </template>
      Are you sure you want to logout your account?
  </ModalDialog>
</template>

<script setup>
import { ref, computed, watch , onMounted  } from 'vue';
import Navigation from '@/layouts/Admin/sidebar.vue'
import {useRouter , useRoute} from 'vue-router'
import {AuthTokenService} from '@/handler/authService';
import {api} from '@/plugins/axios';
import {useConfirmer} from '@/stores/confirmer';
import {useAuthStore} from '@/stores/auth/authStore'
import SnackBar from '@/components/SnackBar.vue'
import Notification from '@/layouts/Admin/Notification.vue'
import { storeToRefs } from "pinia";
import { getUserDefaultRoute, limitString } from '@/utils/utils';
import ChangeTypeModal from '@/components/Dashboard/Tutorial/ChangeExamTypeModal.vue'

import { useNotificationStore } from "@/stores/notification";
const notification = useNotificationStore();
const { fetchNotificationData, fetchUnseenCount } = notification;
const unseenCounter = computed(() => {
  return notification.unseenCounter;
});
import { useInstituteStore } from '@/stores/institute/institute-store';
import { useDashboardStore } from '@/stores/dashboard-store';

import Intercom from '@intercom/messenger-js-sdk';
const institute = useInstituteStore()

const {configData} = storeToRefs(institute)
const confirmer = useConfirmer();
const {logout , clearAuth} = useAuthStore();
const router  = useRouter();
const route = useRoute();
const {openSnackBar} = confirmer
const dashboard = useDashboardStore();
const { streams, isFirstLogin } = storeToRefs(dashboard);
const { getStreams, changeStream, getDashboardData } = dashboard;
const enabledFeatures = computed(() => {
  return configData.value.institute.enabled_features
})

const drawer = ref(true);
const rail = ref(true);
const switchBackEnable = ref(localStorage.getItem('SWITCH_TOKEN') ? true : false)
const store = useAuthStore();
const {user} = storeToRefs(store)
const {getProfile } = store
const confirmDialog = ref(false)
const pages = ref({})
const free_package = ref(true)
const changeExamTypeMenu= ref(false);
const isRenderReady = ref(false)
pages.value = {
  'dashboard': 'My Dashboard',
  'mock-tests': 'My Practice > Mock Test',
  'question-based-tests': 'My Practice > Question Based Test',
  'subscription': 'Subscription',
  'inforamtion': 'Information',
  'inforamtion': 'Information',
  'referal': 'Refer your Friends',
  'contact': 'Contact Us',
  'contactTab': 'Contact Us',
  'faqTab': 'FAQ & Help',
  'information': 'Information',
  'termsTab': 'Terms & Conditions',
  'privacyTab': 'Privacy Policy',
}
const items = ref([
  {
    label: 'Profile',
    value: 'profile',
    icon: '/assets/user-circle-02.svg'
  },
  {
    label: 'Refer to friend',
    value: 'referal',
    icon: '/assets/megaphone-01.svg'
  },
  {
    label: free_package.value ? 'Upgrade' : user.value?.current_package?.name,
    value: 'subscription',
    icon: '/assets/Crown.svg'
  },
  {
    label: 'Logout',
    icon: '/assets/logout-02.svg'
  },
])
const isMobileView = ref(false)
window.addEventListener('resize', function(event) {
  if (window.innerWidth <= 1280) {
    isMobileView.value = true
  }  else if(window.innerWidth >= 1100) {
    rail.value = false;
    isMobileView.value = false
  }
  if(window.innerWidth <= 767) {
    isMobileView.value = true;
  } else {
    isMobileView.value = false;
  }
});

window.addEventListener('load', function() {
  if(window.innerWidth <= 767) {
    isMobileView.value = true;
  } else {
    isMobileView.value = false;
  }
  if(window.innerWidth >= 1100) {
    rail.value = false
  }
});


const logoutUser = async() => {
  await logout().then(res=>{
    clearAuth();
    router.push({name:'login'});
  }).catch(err=>{
    confirmer.openSnackBar({type: 'error', msg: err.response.data.data.detail})
    confirmer.openSnackBar({type: 'error', msg: err.response.data.data.error})
  });
}
onMounted(()=>{
  setTimeout(() => {
    if(enabledFeatures.value.includes('intercom-chat')) {
      Intercom({
          app_id: 'wgokdvuc',
          user_id: user.value.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
          name: user.value.user_full_name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
          email: user.value.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
          created_at: user.value.date_joined, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
        });
    }
  }, 500)
  // if(!user.value){
  //   getProfile().then(res => {
  //     free_package.value = user.value?.current_package?.is_free
  //   })
  // }else{
  //   free_package.value = user.value?.current_package?.is_free
  // }
  getStreams()
  if(window.innerWidth >= 1100) {
    rail.value = false
  }
  getProfile().then(res => {free_package.value = user.value?.current_package?.is_free})
  fetchNotificationData(null)
  fetchUnseenCount()
  setTimeout(() => {
    isRenderReady.value = true
  }, 600)
})

const appBarTitle = computed(() => {
  if (isMobileView.value) {
    return '';
  }
  const routeName = route.name;
  return pages.value[routeName]
});

const profileMenuToggle = ref(false)

const notificationToggle = ref(false)

const closeSidebarOnMobile = (status) => {
  if(window.innerWidth <= 767) {
    setTimeout(() => {
       rail.value = status
    }, 300)
  }
}

//if login is the first then the cookie will be added.
if(AuthTokenService.getCookie('first_login') != null){
  isFirstLogin.value = true
}

const completeIntro = () => {
  // remove the first_login cookie to remove intro
  AuthTokenService.removeCookie('first_login')
  isFirstLogin.value = false
}

const chooseExamType = async (streamId) => {
  try {
    const res = await changeStream(streamId)
    const successMessage = res?.data?.message || 'Stream changed successfully';
    confirmer.openSnackBar({ type: 'success', msg: successMessage });

    await Promise.all([getDashboardData(), getProfile()]);
    router.push({name : 'dashboard'})
  } catch (error) {
    const errorMessage = error?.response?.data?.data?.message 
                         || error?.response?.data?.message
                         || 'An error occurred while changing the stream';
    confirmer.openSnackBar({type: 'error', msg: errorMessage})
  }
}
const switchBack = () => store.switchBack(router)
</script>

<style lang="scss">
.v-btn.align-center .v-btn__content {
  align-items: center;
}

.change-examtype-btn {
  &::before,
  &::after {
      box-shadow: 0px 4px 4px 0px #14885B4D;
      border: 7px solid rgba(#4FC38F99, 0.6);
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      animation: pulsing 2.5s linear infinite;
  }
  &:after {
      animation: pulsing1 2.5s linear infinite;
  }
}
@keyframes pulsing {
	0% {
		opacity: 1;
		transform: scaleY(1) scaleX(1);
	}
	20% {
		opacity: 0.5;
	}
	70% {
		opacity: 0.2;
		transform: scaleY(1.4) scaleX(1.2);
	}
	80% {
		opacity: 0;
		transform: scaleY(1.4) scaleX(1.2);
	}
	90% {
		opacity: 0;
		transform: scaleY(1) scaleX(1);
	}
}
@keyframes pulsing1 {
	0% {
		opacity: 1;
		transform: scaleY(1) scaleX(1);
	}
	20% {
		opacity: 0.5;
	}
	70% {
		opacity: 0.2;
		transform: scaleY(1.3) scaleX(1.15);
	}
	80% {
		opacity: 0;
		transform: scaleY(1.3) scaleX(1.15);
	}
	90% {
		opacity: 0;
		transform: scaleY(1) scaleX(1);
	}
}
</style>