<template>
    <WebLayout page="home" :isGradientBgTop="true">
        <HeroBanner class="pt-12 pt-md-16 pt-lg-20" :content="bannerContent" :cardControl="{bg: ''}" />
        <section class="kiwi-fun-facts-section pt-12 pt-md-16 pt-lg-20">
            <v-container>
                <InfoBox :info="infoContent" />
            </v-container>
        </section>
        <!-- Service Section start -->
        <HeroBanner mainClass="kiwi-services" class="py-12 py-md-16 py-lg-20 pb-lg-10" :content="serviceContent" :cardControl="{bg: '', variant: 'outlined'}" />
        <!-- Service Section end -->
        
        <section class="pt-0 pb-16">
            <v-container>
                    <a href="https://khalti.com/go/?t=bazaar&vendor_idx=GukS8sSWNYPa7MV6i3xMaP" target="_blank"><img src="/assets/khalti-banner.png" alt=""></a> 
            </v-container>

        </section>

        <TwoColSectionArticle 
            mainClass="kiwi-pte-mock-test" 
            bgColor="kiwi-bg-light-green" 
            class="py-12 py-md-16 py-lg-20" 
            :orderControl="{ media: 'first', article: 'last' }" 
            :content="pteMockContent"
        />

        <TwoColSectionArticle 
            mainClass="kiwi-ielts-preparation" 
            bgColor="kiwi-bg-white" 
            class="py-12 py-md-16 py-lg-20" 
            :orderControl="{ media: 'last', article: 'first' }" 
            :content="ieltsPreparation"
        />
        
        <TwoColSectionArticle 
            mainClass="kiwi-pte-test-type" 
            bgColor="kiwi-bg-light-green" 
            class="py-12 py-md-16 py-lg-20" 
            :orderControl="{ media: 'first', article: 'last' }" 
            :content="pteTestTypes"
        />

        <section class="kiwi-section kiwi-testimonial py-12 py-md-16 py-lg-20" >
            <v-container>
                <v-row class="align-center">
                    <v-col cols="12" md="4" lg="4">
                        <Article :content="testimonial.info" />
                    </v-col>
                    <v-col cols="12" md="8" lg="8">
                        <Masonary :items="testimonial.items" />
                    </v-col>
                </v-row>
            </v-container>
        </section>

        <TwoColSectionArticle 
            mainClass="kiwi-ielts-preparation" 
            bgColor="kiwi-bg-light-green" 
            class="py-12 py-md-0 py-lg-0"
            :orderControl="{ media: 'last', article: 'first' }" 
            :content="subscribeContent"
        />
    </WebLayout>
</template>

<script setup>
    import WebLayout from '../WebLayout.vue'
    import HeroBanner from '../Addon/HeroBanner.vue'
    import InfoBox from '../Addon/InfoCard.vue'
    import TwoColSectionArticle from '../Addon/TwoColSectionArticle.vue'
    import Masonary from '../Addon/MasonaryLayout.vue'
    import Article from '../Addon/ArticleFormatCard1.vue'
    import {ref} from 'vue'
    import { usePackageStore } from "@/stores/package-store";
    import { storeToRefs } from 'pinia'

    const packageList = usePackageStore()
    const { packages } = storeToRefs(packageList)
    const { getPackageList } = packageList
    // preload packages on homepage
    if(packages.value.length === 0){
        getPackageList()
    }

    const bannerContent = ref({})

    bannerContent.value = {
        title: `Conquer Your SAT & PTE <br/>Journey with <span class="kiwi-text-primary d-block">Kiwiread!</span>`,
        text: `Your comprehensive online SAT & PTE preparation platform, enhanced with advanced AI to simulate a real exam experience.`,
        action: 'Start Free Trial',
        to: {name: 'register'}
    }

    const infoContent = ref([])

    infoContent.value = [
        {
            counter: '100+',
            text: 'Sign up everyday'
        },
        {
            counter: '3K+',
            text: 'Updated question'
        },
        {
            counter: '2K+',
            text: 'Happy Clients'
        },
        {
            counter: '200+',
            text: 'Daily Practices'
        },
    ]

    const serviceContent = ref({})
    serviceContent.value = {
        category: {
            name: 'Services',
            color: 'kiwi-text-primary'
        },
        title: 'Kiwiread empowers you',
        titleSize: 'kiwi-fs-36',
        text: 'We offer advanced features, providing real-time experience, instant results, and performance analytics to guide your practice.',
        categoryLists: [
            {
                icon: '/assets/bookmark-svgrepo-com 2.svg',
                title: 'Best AI Technology',
                text: 'Our advance AI  provides accurate and instant scores for your SAT & PTE practice, similar to the actual SAT & PTE algorithms'
            },
            {
                icon: '/assets/bookmark-svgrepo-com 5.svg',
                title: 'Detailed Score Analysis:',
                text: `The portal offers an accurate evaluation of a student's performance`
            },
            {
                icon: '/assets/bookmark-svgrepo-com 2 (1).svg',
                title: 'Realistic Exam Experience:',
                text: 'Engage in practice sessions within an environment that closely replicates real SAT & PTE exam conditions.'
            },
        ]
    }

    const pteMockContent = ref({})
    pteMockContent.value = {
        imgSrc: '/assets/Savings.svg',
        category: {
            name: 'Voucher Code',
            color: 'kiwi-text-primary'
        },
        title: 'Maximize your Savings <br/>with Voucher Codes',
        text: 'Users can purchase or subscribe to packages on the KiwiRead platform by applying a pre-purchased voucher code. Agents or individual sellers can distribute these vouchers, and students can use the codes during the payment process on KiwiRead.',
        hasAction: true,
        action: {
            label: 'Learn More',
            color: 'kiwi-bg-primary',
            to: {name: 'register'}
        }
    }

    const ieltsPreparation = ref({})
    ieltsPreparation.value = {
        imgSrc: '/assets/Wallet-bro 1.svg',
        category: {
            name: 'Promo Code',
            color: 'kiwi-text-primary'
        },
        title: 'Get special discounts <br/>during our events!',
        text: 'Kiwiread provides a special discount code, available for a limited time and can be used to save on any package purchase you make.',
        hasAction: true,
        action: {
            label: 'Learn More',
            color: 'kiwi-bg-primary',
            to: {name: 'register'}
        }
    }

    const pteTestTypes = ref({})
    pteTestTypes.value = {
        imgSrc: '/assets/Savings-bro 1 (1).svg',
        category: {
            name: 'Referral Code',
            color: 'kiwi-text-primary',
            transform: 'uppercase',
        },
        title: 'Unlock Savings with <br/>Referral Codes!',
        text: 'When users receive a referral code, they can enjoy discounts on the KiwiRead platform by sharing or receiving it from existing users. Plus, each referral code can be used by multiple people, extending its benefits to even more users.',
        hasAction: true,
        action: {
            label: 'Learn More',
            color: 'kiwi-bg-primary',
            to: {name: 'register'}
        }
    }

    const testimonial = ref({})
    testimonial.value = {
        info: {
            category: {
                name: 'Testimonial',
                color: 'kiwi-text-primary',
                transform: 'uppercase',
            },
            
            title: 'Our Success Story <br/> Read from our <br/>satisfied <span class="kiwi-text-primary">Clients</span>',
            text: 'Our clients are at the core of everything we do. Their satisfaction is not just a ',
        },
        items: [
            {
                id: 1,
                client: {
                    imgSrc: '/assets/testimonial/himangi.jpeg',
                    name: `Hemangi Karn <br/><span class="kiwi-fs-14 kiwi-text-light-black-2">SAT & PTE Journey with KiwiRead:</span>`,
                    cite: ''
                },
                text: '“After college, I wanted to work abroad and needed to improve my English quickly. KiwiRead was perfect. With regular practice and AI feedback, my confidence grew. When I got a job offer requiring a high English score, I knew KiwiRead played a big part in my success.”'
            },
            {
                id: 2,
                client: {
                    imgSrc: '/assets/testimonial/abhishek.jpeg',
                    name: `Abhishek Bhattarai <br/><span class="kiwi-fs-14 kiwi-text-light-black-2">SAT & PTE Journey with KiwiRead:</span>`,
                    cite: ''
                },
                text: `“Balancing work and PTE prep was tough, but KiwiRead's flexible and user friendly platform made it possible. With their AI help, my English improved daily. Thanks to KiwiRead.”`
            },
            {
                id: 3,
                client: {
                    imgSrc: '/assets/testimonial/bhuwan.jpg',
                    name: `Bhuwan Aryal <br/><span class="kiwi-fs-14 kiwi-text-light-black-2">SAT & PTE Journey with KiiwiRead:</span>`,
                    cite: ''
                },
                text: `“Kiwiread is one of the best platform to learn SAT & PTE in Nepal. My score went from overall 70 to overall 90 in my most recent test. PTE Success mock tests are excellent, closely resembling the actual SAT & PTE exam.”`
            },
            {
                id: 4,
                client: {
                    imgSrc: '/assets/testimonial/jhon.jpg',
                    name: `John Novak <br/><span class="kiwi-fs-14 kiwi-text-light-black-2">SAT & PTE Success Story with KiwiRead:</span>`,
                    cite: ''
                },
                text: `“Despite my previous SAT & PTE attempts, KiwiRead showed me there was room for improvement. Their personalized plans and AI feedback helped me target weaknesses and refine my skills.”`
            },
        ]
    }

    const subscribeContent = ref({})
    subscribeContent.value = {
        imgSrc: '/assets/subscribe-img.png',
        setMediaCol: 6,
        setArticleCol: 4,
        category: null,
        title: '<span class="kiwi-text-primary">Sign up</span> today and take control of your SAT & PTE journey!',
        text: `With access to our extensive resources and AI in-built study tools, you'll be empowered  achieve your desired score.`,
        hasAction: true,
        showMask: true,
        action: {
            label: 'Start Free Trial',
            color: 'kiwi-bg-primary',
            to: {name: 'register'}
        }
    }
</script>