<template>
<v-sheet
    class="kiwi-bg-light-grey-6 position-relative overflow-hidden kiwi-text-black h-100 kiwi-radius-8 kiwi-border-light-grey-3 border"
    >
    <img
        src="/assets/eclipse-mask2.png"
        class="position-absolute z-1 top-0"
        alt=""
        style="object-fit: contain; right: 0px"
    />
    <div class="content position-relative z-2 pa-4 " :class="satMode && type === 'question-based-test' ? 'd-flex align-center justify-space-between' : ''">
        <div class="d-flex align-center justify-space-between">
        <div class="flex-grow pr-2">
            <div class="title kiwi-fs-18 font-weight-bold d-flex flex-wrap">
            {{ isQbt ? test.title : test.description }}
              <div class="d-flex duratio align-center kiwi-fs-14 ml-4" v-if="authStore.isSAT && !isQbt">
                <img src="/assets/timer-02.svg" class="mr-1" alt="" style="width: 16px; height: 16px;" />
                <span> {{convertMinutesToHoursAndMinutes(test.total_time_duration)}}</span>
              </div>
            </div>
            <div class="d-flex author-wrapper align-center flex-wrap has-dot-seprator" v-if="!isQbt">
                <div v-if="authStore.isSAT">
                  <span>{{'2 Sections '}}</span>
                </div>
              <v-icon class="mdi mdi-circle-small px-2"></v-icon>
                <div
                    class="author-wrapper kiwi-fs-14 d-flex flex-wrap has-dot-seprator"
                >
                    <template v-if="test?.subjects?.length">
                    <span>{{authStore.isSAT ? 4 :test.subjects.length }}  Modules</span>
                    <v-icon class="mdi mdi-circle-small px-2"></v-icon>
                    </template>
                    <span> {{ test.total_number_of_question }} Questions</span>
                </div>
                <template v-if="test?.total_time_duration">
                <span v-if="!authStore.isSAT"  class="pa-1 d-block">-</span>
                <div class="d-flex duratio align-center kiwi-fs-14" v-if="!authStore.isSAT">
                    <img src="/assets/timer-02.svg" class="mr-1" alt="" style="width: 16px; height: 16px;" />
                    <span > {{ test.total_time_duration ? test.total_time_duration / 60  : 0}}hrs</span>
                </div>
                </template>
            </div>
            <div class="author-wrapper kiwi-fs-14" v-else>
                <span class="font-weight-bold kiwi-fs-14 kiwi-text-black-3 text-uppercase">Advanced Math</span> - <span> {{ test.total_number_of_question }} Questions</span>
            </div>
        </div>
        <template v-if="isQbt">
            <v-chip variant="flat" class="type-easy">{{test?.exam_difficulty}}</v-chip>
        </template>
        </div>
        <div v-if="isQbt" class="kiwi-bg-white kiwi-text-black-3 py-1 px-2 kiwi-radius-8 mt-2" style="border-left: 4px solid var(--color-primary);">Description: {{test?.description}}</div>
    </div>
    <v-divider></v-divider>
    <v-sheet
        class="kiwi-bg-white position-relative overflow-hidden kiwi-text-black h-100"
    >
        <div class="content position-relative z-2 pa-4">
        <div class="d-flex flex-wrap" v-if="type === 'mock-test'">
            <img
            src="/assets/score-icon.svg"
            class="mr-3"
            alt=""
            />
            <div>
            <span class="d-block font-weight-semi-bold kiwi-fs-16"
                >Overall Score</span
            >
            <span
                class="kiwi-text-orange font-weight-semi-bold kiwi-fs-16"
                >{{test.exam_attempt_data?.is_completed ? parseFloat(test.exam_attempt_data?.score) : '--'}}/{{test.total_marks || 90}}</span
            >
            </div>
        </div>
        <div class="d-flex flex-wrap mt-4">
            <v-progress-circular
            :model-value="test.attempted_question_percentage"
            :size="42"
            :width="2"
            class="mr-3"
            >
            <template v-slot:default>
                <div
                class="inner-status d-flex flex-column flex-wrap align-center justify-center"
                >
                <span class="kiwi-fs-12 font-weight-bold"
                    >{{ test.attempted_question_percentage }}%</span
                >
                </div>
            </template>
            </v-progress-circular>
            <div>
            <span
                class="kiwi-fs-16 d-block font-weight-semi-bold"
                style="line-height: 1"
                >Status</span
            >
            <span
                class="kiwi-fs-12 font-weight-medium kiwi-text-light-black-2"
                style="line-height: 1"
                >
                <template v-if="test.is_completed">Completed</template>
                <template v-else-if="test.is_attempted">In Progress</template>
                <template v-else>Not Started</template>
                </span
            >
            </div>
        </div>
        <v-divider class="my-4"></v-divider>

        <v-btn
                v-if="test.is_completed"
                block
                class="kiwi-button text-capitalize font-weight-semi-bold kiwi-text-primary kiwi-border-primary text-center mr-3"
                rounded="xl"
                variant="outlined"
                @click="mockTestAction(test)"
                >View Result
        </v-btn>
        <v-btn
            block
            class="kiwi-button text-capitalize font-weight-semi-bold kiwi-text-white kiwi-bg-warning text-center mr-3"
            rounded="xl"
            variant="flat"
            v-else-if="!test.is_accessible"
            @click="mockTestAction(test)"
            ><v-icon class="la la-crown mr-2"></v-icon>
            <span>Upgrade Plan</span></v-btn>
        <v-btn
                v-else-if="test.is_attempted"
                block
                class="kiwi-button text-capitalize font-weight-semi-bold kiwi-text-primary kiwi-border-primary text-center mr-3"
                rounded="xl"
                variant="outlined"
                @click="resume(test)"
                >Resume
        </v-btn>
        <v-btn
                v-else
                block
                class="kiwi-button text-capitalize font-weight-semi-bold kiwi-text-white kiwi-bg-primary text-center mr-3"
                rounded="xl"
                variant="flat"
                @click="mockTestAction(test)"
                >Start
        </v-btn>
          <v-btn
            class="kiwi-bg-white border kiwi-bg-white kiwi-border-light-grey kiwi-fs-16 text-capitalize me-2 mt-2"
            size="small"
            block
            rounded="xl"
            variant="flat"
            style="padding: 0px 10px; font-size: 14px;"
            @click="restart(test.id)"
            v-if="isQbt && test.is_accessible "
          >Restart</v-btn
          >
          <v-btn
            class="kiwi-bg-white border kiwi-bg-white kiwi-border-light-grey kiwi-fs-16 text-capitalize me-2 mt-2"
            size="small"
            block
            rounded="xl"
            variant="flat"
            style="padding: 0px 10px; font-size: 14px;"
            @click="restartmocktest(test.id)"
          >Restart</v-btn
          >
        </div>
    </v-sheet>
    </v-sheet>
    <PromptDialog v-if="prompt" v-bind="prompt" />
</template>
<script setup>
import { ref } from "vue";
import PromptDialog from "../Prompt/PromptDialog.vue";
import {useExamInstanceStore} from "@/stores/examInstance-store";
import {useAuthStore} from "@/stores/auth/authStore";
import {convertMinutesToHoursAndMinutes} from "@/utils/utils";
const authStore = useAuthStore();
const examInstanceStore = useExamInstanceStore();
import { useTestAction } from "@/composables/useTestAction";
import {useQuestionBasedTestStore} from "@/stores/questionbased-test-store";

const questionBasedTestStore = useQuestionBasedTestStore();
const { restart  , restartmocktest} = questionBasedTestStore;

const props = defineProps({
    test: Object,
    type: {
        type: String,
        default: "mock-test",
    },
    satMode: {
        type: Boolean,
        default: false,
    },
    isQbt :{
      default: false,
    }
});
const {mockTestAction} = useTestAction(props.type)
const prompt = ref(null)
const resume = (test) => {
  prompt.value = {
    title: 'Confirm?',
    content: 'You did not finish this test last time. Do you want to continue from your saved session?',
    actions: [
      {
        text: "Close",
        onClick: () => prompt.value = null,
      },
      {
        text: "Continue",
        type: "primary",
        onClick: () => {
            prompt.value = null
            mockTestAction(test)
        }
      },
    ],
  }
}
</script>
<style lang="scss">
$question-type: (
    'easy': easy,
    'medium': medium,
    'hard': hard,
);
@each $key, $val in $question-type {
    .type-#{$key} {
        background-color: var(--color-#{$val});
        color: var(--color-black-3);
        font-weight: 600;
    }
}
</style>
